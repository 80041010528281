import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import unitModule from "./unitModule";

const ls = new SecureLS({ isCompression: false });

// export default createStore({
const store = createStore({
  state: {
    user: null,
    token: null,
    project: null,
    sidenavOpened: false,
    // *** SCOPE *** //
    stages: null,
    //this is the current frame_stage_id property from the stage object
    currentStageId: null,
    currentStage: null,
    selectedStages: [],
    scopeModal: {
      cmd: null,
      scope: null,
      item: null,
    },
    // *** SIGHT *** //
    loadIFC: false,
    msightLink: null,
    // *** MISC ***//
    clientVersion: null,
    loading: false,
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
    project(state, project) {
      state.project = project;
    },
    stages(state, stages) {
      state.stages = stages;
    },
    currentStageId(state, currentStageId) {
      state.currentStageId = currentStageId;
      console.log("CURRENT STAGE ID CHANGED TO: ", state.currentStageId);
    },
    currentStage(state, currentStage) {
      state.currentStage = state.stages.find(
        (stage) => currentStage == stage.stage_value
      );
      console.log("CURRENT STAGE CHANGED TO: ", state.currentStage);

      state.currentStageId = state.currentStage.frame_stage_id;
      console.log("CURRENT STAGE ID CHANGED TO: ", state.currentStageId);
    },
    selectedStages(state, selectedStages) {
      state.selectedStages = selectedStages;
    },
    updateSelectedStages(state, value) {
      if (!state.selectedStages.includes(value)) {
        state.selectedStages.push(value);
        state.selectedStages.sort();
      } else {
        if (state.selectedStages.length === 1) return;
        state.selectedStages = state.selectedStages.filter(
          (val) => val !== value
        );
      }
      console.log(state.selectedStages);
    },
    sidenavOpened(state, sidenavOpened) {
      state.sidenavOpened = sidenavOpened;
    },
    // *** SCOPE *** //
    scopeModal(state, scopeModal) {
      state.scopeModal = scopeModal;
    },
    // *** SIGHT *** //
    loadIFC(state, loadIFC) {
      state.loadIFC = loadIFC;
    },
    msightLink(state, msightLink) {
      state.msightLink = msightLink;
    },
    logout(state) {
      store.dispatch("clearStore", state);
    },
    // *** MISC *** //
    setClientVersion(state, clientVersion) {
      state.clientVersion = clientVersion;
    },
    loading(state, loading) {
      state.loading = loading;
    },
    //resets state in store to initial values
    clearStore(state) {
      state.user = null;
      state.project = null;
      state.unitModule.unit = null;
      state.sidenavOpened = false;
      state.stages = null;
      state.currentStageId = 1;
      state.currentStage = null;
      state.selectedStages = [];
      state.scopeModal = {
        cmd: null,
        scope: null,
        item: null,
      };
      state.loadIFC = false;
      state.msightLink = null;
      state.loading = false;

      console.log("clearStore called, resetting app");
    },
  },
  actions: {
    user(context, user) {
      context.commit("user", user);
    },
    token(context, token) {
      context.commit("token", token);
    },
    project(context, project) {
      context.commit("project", project);
    },
    stages(context, stages) {
      context.commit("stages", stages);
    },
    currentStageId(context, currentStageId) {
      context.commit("currentStageId", currentStageId);
    },

    setCurrentStage(context, currentStage) {
      //this will compare the value passed and set the whole object as the current stage (see mutations)
      context.commit("currentStage", currentStage);
    },
    selectedStages(context, selectedStages) {
      context.commit("selectedStages", selectedStages);
    },
    updateSelectedStages({ commit }, value) {
      commit("updateSelectedStages", value);
    },
    sidenavOpened(context, sidenavOpened) {
      context.commit("sidenavOpened", sidenavOpened);
    },
    // *** SCOPE *** //
    scopeModal(context, scopeModal) {
      context.commit("scopeModal", scopeModal);
    },
    // *** SIGHT *** //
    loadIFC(context, loadIFC) {
      context.commit("loadIFC", loadIFC);
    },
    msightLink(context, msightLink) {
      context.commit("msightLink", msightLink);
    },
    // *** MISC *** //
    logout(context) {
      context.commit("logout");
    },
    setClientVersion(context, clientVersion) {
      context.commit("setClientVersion", clientVersion);
    },
    clearStore(context) {
      context.commit("clearStore");
    },
    setLoading(context, loading) {
      context.commit("loading", loading);
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    project: (state) => {
      return state.project;
    },
    stages: (state) => {
      return state.stages;
    },
    currentStageId: (state) => {
      return state.currentStageId;
    },
    getCurrentStage: (state) => {
      return state.currentStage;
    },
    selectedStages: (state) => {
      return state.selectedStages;
    },
    sidenavOpened: (state) => {
      return state.sidenavOpened;
    },
    scopeModal: (state) => {
      return state.scopeModal;
    },
    // *** SIGHT *** //
    loadIFC: (state) => {
      return state.loadIFC;
    },
    msightLink: (state) => {
      return state.msightLink;
    },
    // *** MISC *** //
    clientVersion: (state) => {
      return state.clientVersion;
    },
    loading: (state) => {
      return state.loading;
    },
  },
  modules: {
    unitModule,
  },

  // plugins: [createPersistedState()]
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export default store;
